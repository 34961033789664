import { Link } from "gatsby";
import * as React from "react";

const NotFoundPage = () => {
    
  return (
    <div className='flex flex-col justify-center w-screen h-screen px-5 pb-16 lg:pb-0 lg:px-36 bg-secondary-blue text-primary-yellow'>
      <span className='text-5xl uppercase lg:text-9xl'>error 404</span>
      <span className='px-1 text-base lg:px-3 lg:text-xl'>Page not found</span>
      <span className='px-1 mt-10 text-lg lg:text-2xl lg:px-3'>We're sorry, the page you requested could not be found. Please go back to the homepage or an avaliable page!</span>
      <div className='fixed right-0 flex justify-end w-full pr-5 lg:pr-36 bottom-10'>
        <Link to='/'>
          <div className="px-4 py-2 text-xs uppercase duration-300 border-[1px] lg:border-2 rounded-full lg:text-base border-primary-yellow hover:bg-primary-yellow hover:text-secondary-blue">
            back to the comic page
          </div>
        </Link>
      </div>
    </div>
  )
}

export default NotFoundPage;